import React from "react";
import NextLink from "next/link";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";

import Container from "../components/Common/Container";
import NotFoundIllustration from "../components/404/NotFound";

const NotFound = () => {
  const theme = useTheme();
  const { t } = useTranslation("404");
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      minHeight={"calc(100vh - 64px - 183px)"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      bgcolor={theme.palette.alternate.main}
    >
      <Container>
        <Grid container spacing={6}>
          <Grid item container justifyContent={"center"} xs={12} md={6}>
            <Box
              height={"100%"}
              width={"100%"}
              maxWidth={{ xs: 500, md: "100%" }}
            >
              <NotFoundIllustration width={"100%"} height={"100%"} />
            </Box>
          </Grid>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"center"}
            xs={12}
            md={6}
          >
            <Box>
              <Typography
                variant="h1"
                component={"h1"}
                align={isMd ? "left" : "center"}
                sx={{ fontWeight: 700 }}
              >
                404
              </Typography>
              <Typography
                variant="h6"
                component="p"
                color="textSecondary"
                align={isMd ? "left" : "center"}
              >
                {t("hero.title")}
              </Typography>
              <Box
                marginTop={4}
                display={"flex"}
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                <NextLink href="/" passHref>
                  <Button
                    variant="contained"
                    color="primary"
                    component="a"
                    size="large"
                  >
                    {t("hero.cta")}
                  </Button>
                </NextLink>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NotFound;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "404"])),
    },
  };
}
